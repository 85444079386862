/*!
 * html5-device-mockups (https://github.com/pixelsign/html5-device-mockups)
 * Copyright 2013 - 2017 pixelsign
 * Licensed under MIT (https://github.com/pixelsign/html5-device-mockups/blob/master/LICENSE.txt)
 * Last Build: Tue Nov 14 2017 12:40:57
 */
/*!
 * html5-device-mockups (https://github.com/pixelsign/html5-device-mockups)
 * Copyright 2013 - 2017 pixelsign
 * Licensed under MIT (https://github.com/pixelsign/html5-device-mockups/blob/master/LICENSE.txt)
 * Last Build: Tue Nov 14 2017 12:39:20
 */
.device-wrapper {
  max-width: 300px;
  width: 100%; }

.device {
  position: relative;
  background-size: cover; }
  .device .screen {
    position: absolute;
    background-size: cover;
    pointer-events: none; }
  .device .button {
    position: absolute;
    cursor: pointer; }

.device[data-device="galaxyS3"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 194.71789%;
  background-image: url(../device-mockups/galaxyS3/portrait_black.png); }
  .device[data-device="galaxyS3"][data-orientation="portrait"][data-color="black"] .screen {
    top: 9.67941%;
    left: 6.60264%;
    width: 86.31453%;
    height: 78.91492%; }
  .device[data-device="galaxyS3"][data-orientation="portrait"][data-color="black"] .button {
    top: 93.1566%;
    left: 38.65546%;
    width: 22.56903%;
    height: 3.26757%; }

.device[data-device="galaxyS3"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 194.71789%;
  background-image: url(../device-mockups/galaxyS3/portrait_white.png); }
  .device[data-device="galaxyS3"][data-orientation="portrait"][data-color="white"] .screen {
    top: 9.67941%;
    left: 6.60264%;
    width: 86.31453%;
    height: 78.91492%; }
  .device[data-device="galaxyS3"][data-orientation="portrait"][data-color="white"] .button {
    top: 93.1566%;
    left: 38.65546%;
    width: 22.56903%;
    height: 3.26757%; }

.device[data-device="galaxyS3"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 51.418%;
  background-image: url(../device-mockups/galaxyS3/landscape_black.png); }
  .device[data-device="galaxyS3"][data-orientation="landscape"][data-color="black"] .screen {
    top: 7.07434%;
    left: 9.37115%;
    width: 78.91492%;
    height: 86.33094%; }
  .device[data-device="galaxyS3"][data-orientation="landscape"][data-color="black"] .button {
    top: 38.84892%;
    left: 93.34155%;
    width: 3.39088%;
    height: 22.54197%; }

.device[data-device="galaxyS3"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 51.418%;
  background-image: url(../device-mockups/galaxyS3/landscape_white.png); }
  .device[data-device="galaxyS3"][data-orientation="landscape"][data-color="white"] .screen {
    top: 7.07434%;
    left: 9.37115%;
    width: 78.91492%;
    height: 86.33094%; }
  .device[data-device="galaxyS3"][data-orientation="landscape"][data-color="white"] .button {
    top: 38.84892%;
    left: 93.34155%;
    width: 3.39088%;
    height: 22.54197%; }

.device[data-device="galaxyS5"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 193.98374%;
  background-image: url(../device-mockups/galaxyS5/portrait_black.png); }
  .device[data-device="galaxyS5"][data-orientation="portrait"][data-color="black"] .screen {
    top: 9.80721%;
    left: 6.09756%;
    width: 87.80488%;
    height: 80.4694%; }
  .device[data-device="galaxyS5"][data-orientation="portrait"][data-color="black"] .button {
    top: 92.62364%;
    left: 38.94309%;
    width: 22.52033%;
    height: 3.73009%; }

.device[data-device="galaxyS5"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 193.98374%;
  background-image: url(../device-mockups/galaxyS5/portrait_white.png); }
  .device[data-device="galaxyS5"][data-orientation="portrait"][data-color="white"] .screen {
    top: 9.80721%;
    left: 6.09756%;
    width: 87.80488%;
    height: 80.4694%; }
  .device[data-device="galaxyS5"][data-orientation="portrait"][data-color="white"] .button {
    top: 92.62364%;
    left: 38.94309%;
    width: 22.52033%;
    height: 3.73009%; }

.device[data-device="galaxyS5"][data-orientation="portrait"][data-color="gold"] {
  padding-bottom: 193.98374%;
  background-image: url(../device-mockups/galaxyS5/portrait_gold.png); }
  .device[data-device="galaxyS5"][data-orientation="portrait"][data-color="gold"] .screen {
    top: 9.80721%;
    left: 6.09756%;
    width: 87.80488%;
    height: 80.4694%; }
  .device[data-device="galaxyS5"][data-orientation="portrait"][data-color="gold"] .button {
    top: 92.62364%;
    left: 38.94309%;
    width: 22.52033%;
    height: 3.73009%; }

.device[data-device="galaxyS5"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 51.55071%;
  background-image: url(../device-mockups/galaxyS5/landscape_black.png); }
  .device[data-device="galaxyS5"][data-orientation="landscape"][data-color="black"] .screen {
    top: 6.09756%;
    left: 9.80721%;
    width: 80.4694%;
    height: 87.80488%; }
  .device[data-device="galaxyS5"][data-orientation="landscape"][data-color="black"] .button {
    top: 38.53659%;
    left: 92.62364%;
    width: 3.73009%;
    height: 22.52033%; }

.device[data-device="galaxyS5"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 51.55071%;
  background-image: url(../device-mockups/galaxyS5/landscape_white.png); }
  .device[data-device="galaxyS5"][data-orientation="landscape"][data-color="white"] .screen {
    top: 6.09756%;
    left: 9.80721%;
    width: 80.4694%;
    height: 87.80488%; }
  .device[data-device="galaxyS5"][data-orientation="landscape"][data-color="white"] .button {
    top: 38.53659%;
    left: 92.62364%;
    width: 3.73009%;
    height: 22.52033%; }

.device[data-device="galaxyS5"][data-orientation="landscape"][data-color="gold"] {
  padding-bottom: 51.55071%;
  background-image: url(../device-mockups/galaxyS5/landscape_gold.png); }
  .device[data-device="galaxyS5"][data-orientation="landscape"][data-color="gold"] .screen {
    top: 6.09756%;
    left: 9.80721%;
    width: 80.4694%;
    height: 87.80488%; }
  .device[data-device="galaxyS5"][data-orientation="landscape"][data-color="gold"] .button {
    top: 38.53659%;
    left: 92.62364%;
    width: 3.73009%;
    height: 22.52033%; }

.device[data-device="galaxyTab4"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 168.33616%;
  background-image: url(../device-mockups/galaxyTab4/portrait_black.png); }
  .device[data-device="galaxyTab4"][data-orientation="portrait"][data-color="black"] .screen {
    top: 8.57287%;
    left: 5.68761%;
    width: 87.94567%;
    height: 83.00555%; }
  .device[data-device="galaxyTab4"][data-orientation="portrait"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="galaxyTab4"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 168.33616%;
  background-image: url(../device-mockups/galaxyTab4/portrait_white.png); }
  .device[data-device="galaxyTab4"][data-orientation="portrait"][data-color="white"] .screen {
    top: 8.52244%;
    left: 5.68761%;
    width: 87.94567%;
    height: 83.05598%; }
  .device[data-device="galaxyTab4"][data-orientation="portrait"][data-color="white"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="iMac"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 80.46663%;
  background-image: url(../device-mockups/iMac/portrait_black.png); }
  .device[data-device="iMac"][data-orientation="portrait"][data-color="black"] .screen {
    top: 5.05732%;
    left: 4.06945%;
    width: 91.8611%;
    height: 64.1942%; }
  .device[data-device="iMac"][data-orientation="portrait"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="iPad"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 129.56989%;
  background-image: url(../device-mockups/iPad/portrait_black.png); }
  .device[data-device="iPad"][data-orientation="portrait"][data-color="black"] .screen {
    top: 9.12863%;
    left: 10.30466%;
    width: 79.21147%;
    height: 81.39696%; }
  .device[data-device="iPad"][data-orientation="portrait"][data-color="black"] .button {
    top: 93.15353%;
    left: 47.13262%;
    width: 6.00358%;
    height: 4.49516%; }

.device[data-device="iPad"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 129.56989%;
  background-image: url(../device-mockups/iPad/portrait_white.png); }
  .device[data-device="iPad"][data-orientation="portrait"][data-color="white"] .screen {
    top: 9.12863%;
    left: 10.48387%;
    width: 79.30108%;
    height: 81.39696%; }
  .device[data-device="iPad"][data-orientation="portrait"][data-color="white"] .button {
    top: 93.15353%;
    left: 47.13262%;
    width: 6.00358%;
    height: 4.49516%; }

.device[data-device="iPad"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 77.04011%;
  background-image: url(../device-mockups/iPad/landscape_black.png); }
  .device[data-device="iPad"][data-orientation="landscape"][data-color="black"] .screen {
    top: 10.23339%;
    left: 9.26694%;
    width: 81.3278%;
    height: 79.44345%; }
  .device[data-device="iPad"][data-orientation="landscape"][data-color="black"] .button {
    top: 46.7684%;
    left: 93.22268%;
    width: 4.426%;
    height: 6.01436%; }

.device[data-device="iPad"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 77.04011%;
  background-image: url(../device-mockups/iPad/landscape_white.png); }
  .device[data-device="iPad"][data-orientation="landscape"][data-color="white"] .screen {
    top: 10.23339%;
    left: 9.26694%;
    width: 81.3278%;
    height: 79.44345%; }
  .device[data-device="iPad"][data-orientation="landscape"][data-color="white"] .button {
    top: 46.7684%;
    left: 93.22268%;
    width: 4.426%;
    height: 6.01436%; }

.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 141.39298%;
  background-image: url(../device-mockups/iPadAir2/portrait_black.png); }
  .device[data-device="iPadAir2"][data-orientation="portrait"][data-color="black"] .screen {
    top: 9.09091%;
    left: 6.45527%;
    width: 86.97622%;
    height: 82.01842%; }
  .device[data-device="iPadAir2"][data-orientation="portrait"][data-color="black"] .button {
    top: 93.55226%;
    left: 46.82899%;
    width: 6.28539%;
    height: 4.44533%; }

.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 141.39298%;
  background-image: url(../device-mockups/iPadAir2/portrait_white.png); }
  .device[data-device="iPadAir2"][data-orientation="portrait"][data-color="white"] .screen {
    top: 9.09091%;
    left: 6.45527%;
    width: 86.97622%;
    height: 82.01842%; }
  .device[data-device="iPadAir2"][data-orientation="portrait"][data-color="white"] .button {
    top: 93.55226%;
    left: 46.82899%;
    width: 6.28539%;
    height: 4.44533%; }

.device[data-device="iPadAir2"][data-orientation="portrait"][data-color="gold"] {
  padding-bottom: 141.39298%;
  background-image: url(../device-mockups/iPadAir2/portrait_gold.png); }
  .device[data-device="iPadAir2"][data-orientation="portrait"][data-color="gold"] .screen {
    top: 9.09091%;
    left: 6.45527%;
    width: 86.97622%;
    height: 82.01842%; }
  .device[data-device="iPadAir2"][data-orientation="portrait"][data-color="gold"] .button {
    top: 93.55226%;
    left: 46.82899%;
    width: 6.28539%;
    height: 4.44533%; }

.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 70.72487%;
  background-image: url(../device-mockups/iPadAir2/landscape_black.png); }
  .device[data-device="iPadAir2"][data-orientation="landscape"][data-color="black"] .screen {
    top: 6.56852%;
    left: 9.09091%;
    width: 82.01842%;
    height: 86.97622%; }
  .device[data-device="iPadAir2"][data-orientation="landscape"][data-color="black"] .button {
    top: 46.88562%;
    left: 93.55226%;
    width: 4.44533%;
    height: 6.28539%; }

.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 70.72487%;
  background-image: url(../device-mockups/iPadAir2/landscape_white.png); }
  .device[data-device="iPadAir2"][data-orientation="landscape"][data-color="white"] .screen {
    top: 6.56852%;
    left: 9.09091%;
    width: 82.01842%;
    height: 86.97622%; }
  .device[data-device="iPadAir2"][data-orientation="landscape"][data-color="white"] .button {
    top: 46.88562%;
    left: 93.55226%;
    width: 4.44533%;
    height: 6.28539%; }

.device[data-device="iPadAir2"][data-orientation="landscape"][data-color="gold"] {
  padding-bottom: 70.72487%;
  background-image: url(../device-mockups/iPadAir2/landscape_gold.png); }
  .device[data-device="iPadAir2"][data-orientation="landscape"][data-color="gold"] .screen {
    top: 6.56852%;
    left: 9.09091%;
    width: 82.01842%;
    height: 86.97622%; }
  .device[data-device="iPadAir2"][data-orientation="landscape"][data-color="gold"] .button {
    top: 46.88562%;
    left: 93.55226%;
    width: 4.44533%;
    height: 6.28539%; }

.device[data-device="iPadPro"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 138.62249%;
  background-image: url(../device-mockups/iPadPro/portrait_black.png); }
  .device[data-device="iPadPro"][data-orientation="portrait"][data-color="black"] .screen {
    top: 7.04403%;
    left: 5.36181%;
    width: 89.23278%;
    height: 85.91195%; }
  .device[data-device="iPadPro"][data-orientation="portrait"][data-color="black"] .button {
    top: 94.93711%;
    left: 47.60244%;
    width: 4.83871%;
    height: 3.52201%; }

.device[data-device="iPadPro"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 138.62249%;
  background-image: url(../device-mockups/iPadPro/portrait_white.png); }
  .device[data-device="iPadPro"][data-orientation="portrait"][data-color="white"] .screen {
    top: 7.04403%;
    left: 5.36181%;
    width: 89.23278%;
    height: 85.91195%; }
  .device[data-device="iPadPro"][data-orientation="portrait"][data-color="white"] .button {
    top: 94.93711%;
    left: 47.60244%;
    width: 4.83871%;
    height: 3.52201%; }

.device[data-device="iPadPro"][data-orientation="portrait"][data-color="gold"] {
  padding-bottom: 138.62249%;
  background-image: url(../device-mockups/iPadPro/portrait_gold.png); }
  .device[data-device="iPadPro"][data-orientation="portrait"][data-color="gold"] .screen {
    top: 7.07547%;
    left: 5.36181%;
    width: 89.23278%;
    height: 85.8805%; }
  .device[data-device="iPadPro"][data-orientation="portrait"][data-color="gold"] .button {
    top: 94.93711%;
    left: 47.60244%;
    width: 4.83871%;
    height: 3.52201%; }

.device[data-device="iPadPro"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 72.13836%;
  background-image: url(../device-mockups/iPadPro/landscape_black.png); }
  .device[data-device="iPadPro"][data-orientation="landscape"][data-color="black"] .screen {
    top: 5.40541%;
    left: 7.04403%;
    width: 85.91195%;
    height: 89.23278%; }
  .device[data-device="iPadPro"][data-orientation="landscape"][data-color="black"] .button {
    top: 47.55885%;
    left: 94.93711%;
    width: 3.52201%;
    height: 4.83871%; }

.device[data-device="iPadPro"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 72.13836%;
  background-image: url(../device-mockups/iPadPro/landscape_white.png); }
  .device[data-device="iPadPro"][data-orientation="landscape"][data-color="white"] .screen {
    top: 5.40541%;
    left: 7.04403%;
    width: 85.91195%;
    height: 89.23278%; }
  .device[data-device="iPadPro"][data-orientation="landscape"][data-color="white"] .button {
    top: 47.55885%;
    left: 94.93711%;
    width: 3.52201%;
    height: 4.83871%; }

.device[data-device="iPadPro"][data-orientation="landscape"][data-color="gold"] {
  padding-bottom: 72.14712%;
  background-image: url(../device-mockups/iPadPro/landscape_gold.png); }
  .device[data-device="iPadPro"][data-orientation="landscape"][data-color="gold"] .screen {
    top: 5.40305%;
    left: 7.07325%;
    width: 85.88494%;
    height: 89.23747%; }
  .device[data-device="iPadPro"][data-orientation="landscape"][data-color="gold"] .button {
    top: 47.53813%;
    left: 94.90726%;
    width: 3.55234%;
    height: 4.88017%; }

.device[data-device="iPhone6"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 207.62016%;
  background-image: url(../device-mockups/iPhone6/portrait_black.png); }
  .device[data-device="iPhone6"][data-orientation="portrait"][data-color="black"] .screen {
    top: 12.25296%;
    left: 6.09613%;
    width: 88.0422%;
    height: 75.32468%; }
  .device[data-device="iPhone6"][data-orientation="portrait"][data-color="black"] .button {
    top: 89.32806%;
    left: 41.85229%;
    width: 16.64713%;
    height: 8.01807%; }

.device[data-device="iPhone6"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 207.62016%;
  background-image: url(../device-mockups/iPhone6/portrait_white.png); }
  .device[data-device="iPhone6"][data-orientation="portrait"][data-color="white"] .screen {
    top: 12.25296%;
    left: 6.09613%;
    width: 88.0422%;
    height: 75.32468%; }
  .device[data-device="iPhone6"][data-orientation="portrait"][data-color="white"] .button {
    top: 89.32806%;
    left: 41.85229%;
    width: 16.64713%;
    height: 8.01807%; }

.device[data-device="iPhone6"][data-orientation="portrait"][data-color="gold"] {
  padding-bottom: 207.62016%;
  background-image: url(../device-mockups/iPhone6/portrait_gold.png); }
  .device[data-device="iPhone6"][data-orientation="portrait"][data-color="gold"] .screen {
    top: 12.25296%;
    left: 6.09613%;
    width: 88.0422%;
    height: 75.32468%; }
  .device[data-device="iPhone6"][data-orientation="portrait"][data-color="gold"] .button {
    top: 89.32806%;
    left: 41.85229%;
    width: 16.64713%;
    height: 8.01807%; }

.device[data-device="iPhone6"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 48.16488%;
  background-image: url(../device-mockups/iPhone6/landscape_black.png); }
  .device[data-device="iPhone6"][data-orientation="landscape"][data-color="black"] .screen {
    top: 5.86166%;
    left: 12.30943%;
    width: 75.26821%;
    height: 87.92497%; }
  .device[data-device="iPhone6"][data-orientation="landscape"][data-color="black"] .button {
    top: 41.50059%;
    left: 89.32806%;
    width: 8.01807%;
    height: 16.64713%; }

.device[data-device="iPhone6"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 48.16488%;
  background-image: url(../device-mockups/iPhone6/landscape_white.png); }
  .device[data-device="iPhone6"][data-orientation="landscape"][data-color="white"] .screen {
    top: 5.86166%;
    left: 12.30943%;
    width: 75.26821%;
    height: 87.92497%; }
  .device[data-device="iPhone6"][data-orientation="landscape"][data-color="white"] .button {
    top: 41.50059%;
    left: 89.32806%;
    width: 8.01807%;
    height: 16.64713%; }

.device[data-device="iPhone6"][data-orientation="landscape"][data-color="gold"] {
  padding-bottom: 48.16488%;
  background-image: url(../device-mockups/iPhone6/landscape_gold.png); }
  .device[data-device="iPhone6"][data-orientation="landscape"][data-color="gold"] .screen {
    top: 5.86166%;
    left: 12.30943%;
    width: 75.26821%;
    height: 87.92497%; }
  .device[data-device="iPhone6"][data-orientation="landscape"][data-color="gold"] .button {
    top: 41.50059%;
    left: 89.32806%;
    width: 8.01807%;
    height: 16.64713%; }

.device[data-device="iPhone6Plus"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 201.45161%;
  background-image: url(../device-mockups/iPhone6Plus/portrait_black.png); }
  .device[data-device="iPhone6Plus"][data-orientation="portrait"][data-color="black"] .screen {
    top: 11.48919%;
    left: 6.53226%;
    width: 87.09677%;
    height: 76.86149%; }
  .device[data-device="iPhone6Plus"][data-orientation="portrait"][data-color="black"] .button {
    top: 90.43235%;
    left: 43.30645%;
    width: 13.3871%;
    height: 6.64532%; }

.device[data-device="iPhone6Plus"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 201.45161%;
  background-image: url(../device-mockups/iPhone6Plus/portrait_white.png); }
  .device[data-device="iPhone6Plus"][data-orientation="portrait"][data-color="white"] .screen {
    top: 11.48919%;
    left: 6.53226%;
    width: 87.09677%;
    height: 76.86149%; }
  .device[data-device="iPhone6Plus"][data-orientation="portrait"][data-color="white"] .button {
    top: 90.43235%;
    left: 43.30645%;
    width: 13.3871%;
    height: 6.64532%; }

.device[data-device="iPhone6Plus"][data-orientation="portrait"][data-color="gold"] {
  padding-bottom: 201.45161%;
  background-image: url(../device-mockups/iPhone6Plus/portrait_gold.png); }
  .device[data-device="iPhone6Plus"][data-orientation="portrait"][data-color="gold"] .screen {
    top: 11.48919%;
    left: 6.53226%;
    width: 87.09677%;
    height: 76.86149%; }
  .device[data-device="iPhone6Plus"][data-orientation="portrait"][data-color="gold"] .button {
    top: 90.43235%;
    left: 43.30645%;
    width: 13.3871%;
    height: 6.64532%; }

.device[data-device="iPhone6Plus"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 49.63971%;
  background-image: url(../device-mockups/iPhone6Plus/landscape_black.png); }
  .device[data-device="iPhone6Plus"][data-orientation="landscape"][data-color="black"] .screen {
    top: 6.37097%;
    left: 11.48919%;
    width: 76.86149%;
    height: 87.09677%; }
  .device[data-device="iPhone6Plus"][data-orientation="landscape"][data-color="black"] .button {
    top: 43.30645%;
    left: 90.43235%;
    width: 6.64532%;
    height: 13.3871%; }

.device[data-device="iPhone6Plus"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 49.63971%;
  background-image: url(../device-mockups/iPhone6Plus/landscape_white.png); }
  .device[data-device="iPhone6Plus"][data-orientation="landscape"][data-color="white"] .screen {
    top: 6.37097%;
    left: 11.48919%;
    width: 76.86149%;
    height: 87.09677%; }
  .device[data-device="iPhone6Plus"][data-orientation="landscape"][data-color="white"] .button {
    top: 43.30645%;
    left: 90.43235%;
    width: 6.64532%;
    height: 13.3871%; }

.device[data-device="iPhone6Plus"][data-orientation="landscape"][data-color="gold"] {
  padding-bottom: 49.63971%;
  background-image: url(../device-mockups/iPhone6Plus/landscape_gold.png); }
  .device[data-device="iPhone6Plus"][data-orientation="landscape"][data-color="gold"] .screen {
    top: 6.37097%;
    left: 11.48919%;
    width: 76.86149%;
    height: 87.09677%; }
  .device[data-device="iPhone6Plus"][data-orientation="landscape"][data-color="gold"] .button {
    top: 43.30645%;
    left: 90.43235%;
    width: 6.64532%;
    height: 13.3871%; }

.device[data-device="iPhoneSE"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 209.82962%;
  background-image: url(../device-mockups/iPhoneSE/portrait_black.png); }
  .device[data-device="iPhoneSE"][data-orientation="portrait"][data-color="black"] .screen {
    top: 14.80325%;
    left: 8.519%;
    width: 83.87942%;
    height: 70.95565%; }
  .device[data-device="iPhoneSE"][data-orientation="portrait"][data-color="black"] .button {
    top: 88.50718%;
    left: 41.67759%;
    width: 17.69332%;
    height: 8.43223%; }

.device[data-device="iPhoneSE"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 209.82962%;
  background-image: url(../device-mockups/iPhoneSE/portrait_white.png); }
  .device[data-device="iPhoneSE"][data-orientation="portrait"][data-color="white"] .screen {
    top: 14.80325%;
    left: 8.519%;
    width: 83.87942%;
    height: 70.95565%; }
  .device[data-device="iPhoneSE"][data-orientation="portrait"][data-color="white"] .button {
    top: 88.50718%;
    left: 41.67759%;
    width: 17.69332%;
    height: 8.43223%; }

.device[data-device="iPhoneSE"][data-orientation="portrait"][data-color="gold"] {
  padding-bottom: 209.82962%;
  background-image: url(../device-mockups/iPhoneSE/portrait_gold.png); }
  .device[data-device="iPhoneSE"][data-orientation="portrait"][data-color="gold"] .screen {
    top: 14.80325%;
    left: 8.519%;
    width: 83.87942%;
    height: 70.95565%; }
  .device[data-device="iPhoneSE"][data-orientation="portrait"][data-color="gold"] .button {
    top: 88.50718%;
    left: 41.67759%;
    width: 17.69332%;
    height: 8.43223%; }

.device[data-device="iPhoneSE"][data-orientation="portrait"][data-color="pink"] {
  padding-bottom: 209.82962%;
  background-image: url(../device-mockups/iPhoneSE/portrait_pink.png); }
  .device[data-device="iPhoneSE"][data-orientation="portrait"][data-color="pink"] .screen {
    top: 14.80325%;
    left: 8.519%;
    width: 83.87942%;
    height: 70.95565%; }
  .device[data-device="iPhoneSE"][data-orientation="portrait"][data-color="pink"] .button {
    top: 88.50718%;
    left: 41.67759%;
    width: 17.69332%;
    height: 8.43223%; }

.device[data-device="iPhoneSE"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 47.65771%;
  background-image: url(../device-mockups/iPhoneSE/landscape_black.png); }
  .device[data-device="iPhoneSE"][data-orientation="landscape"][data-color="black"] .screen {
    top: 7.60157%;
    left: 14.80325%;
    width: 70.95565%;
    height: 83.87942%; }
  .device[data-device="iPhoneSE"][data-orientation="landscape"][data-color="black"] .button {
    top: 40.6291%;
    left: 88.50718%;
    width: 8.43223%;
    height: 17.69332%; }

.device[data-device="iPhoneSE"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 47.65771%;
  background-image: url(../device-mockups/iPhoneSE/landscape_white.png); }
  .device[data-device="iPhoneSE"][data-orientation="landscape"][data-color="white"] .screen {
    top: 7.60157%;
    left: 14.80325%;
    width: 70.95565%;
    height: 83.87942%; }
  .device[data-device="iPhoneSE"][data-orientation="landscape"][data-color="white"] .button {
    top: 40.6291%;
    left: 88.50718%;
    width: 8.43223%;
    height: 17.69332%; }

.device[data-device="iPhoneSE"][data-orientation="landscape"][data-color="gold"] {
  padding-bottom: 47.65771%;
  background-image: url(../device-mockups/iPhoneSE/landscape_gold.png); }
  .device[data-device="iPhoneSE"][data-orientation="landscape"][data-color="gold"] .screen {
    top: 7.60157%;
    left: 14.80325%;
    width: 70.95565%;
    height: 83.87942%; }
  .device[data-device="iPhoneSE"][data-orientation="landscape"][data-color="gold"] .button {
    top: 40.6291%;
    left: 88.50718%;
    width: 8.43223%;
    height: 17.69332%; }

.device[data-device="iPhoneSE"][data-orientation="landscape"][data-color="pink"] {
  padding-bottom: 47.65771%;
  background-image: url(../device-mockups/iPhoneSE/landscape_pink.png); }
  .device[data-device="iPhoneSE"][data-orientation="landscape"][data-color="pink"] .screen {
    top: 7.60157%;
    left: 14.80325%;
    width: 70.95565%;
    height: 83.87942%; }
  .device[data-device="iPhoneSE"][data-orientation="landscape"][data-color="pink"] .button {
    top: 40.6291%;
    left: 88.50718%;
    width: 8.43223%;
    height: 17.69332%; }

.device[data-device="iPhone5"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 209.46124%;
  background-image: url(../device-mockups/iPhone5/portrait_black.png); }
  .device[data-device="iPhone5"][data-orientation="portrait"][data-color="black"] .screen {
    top: 14.80552%;
    left: 8.27858%;
    width: 84.23127%;
    height: 71.26725%; }
  .device[data-device="iPhone5"][data-orientation="portrait"][data-color="black"] .button {
    top: 88.51945%;
    left: 41.52431%;
    width: 18.00263%;
    height: 8.59473%; }

.device[data-device="iPhone5"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 209.46124%;
  background-image: url(../device-mockups/iPhone5/portrait_white.png); }
  .device[data-device="iPhone5"][data-orientation="portrait"][data-color="white"] .screen {
    top: 14.80552%;
    left: 8.27858%;
    width: 84.23127%;
    height: 71.26725%; }
  .device[data-device="iPhone5"][data-orientation="portrait"][data-color="white"] .button {
    top: 88.51945%;
    left: 41.52431%;
    width: 18.00263%;
    height: 8.59473%; }

.device[data-device="iPhone5"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 47.74153%;
  background-image: url(../device-mockups/iPhone5/landscape_black.png); }
  .device[data-device="iPhone5"][data-orientation="landscape"][data-color="black"] .screen {
    top: 7.49014%;
    left: 14.80552%;
    width: 71.26725%;
    height: 84.23127%; }
  .device[data-device="iPhone5"][data-orientation="landscape"][data-color="black"] .button {
    top: 40.47306%;
    left: 88.51945%;
    width: 8.59473%;
    height: 18.00263%; }

.device[data-device="iPhone5"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 47.74153%;
  background-image: url(../device-mockups/iPhone5/landscape_white.png); }
  .device[data-device="iPhone5"][data-orientation="landscape"][data-color="white"] .screen {
    top: 7.49014%;
    left: 14.80552%;
    width: 71.26725%;
    height: 84.23127%; }
  .device[data-device="iPhone5"][data-orientation="landscape"][data-color="white"] .button {
    top: 40.47306%;
    left: 88.51945%;
    width: 8.59473%;
    height: 18.00263%; }

.device[data-device="Lumia930"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 192.31288%;
  background-image: url(../device-mockups/Lumia930/portrait_black.png); }
  .device[data-device="Lumia930"][data-orientation="portrait"][data-color="black"] .screen {
    top: 7.11781%;
    left: 5.52933%;
    width: 87.86244%;
    height: 81.13604%; }
  .device[data-device="Lumia930"][data-orientation="portrait"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="Lumia930"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 192.31288%;
  background-image: url(../device-mockups/Lumia930/portrait_white.png); }
  .device[data-device="Lumia930"][data-orientation="portrait"][data-color="white"] .screen {
    top: 7.11781%;
    left: 5.52933%;
    width: 87.86244%;
    height: 81.13604%; }
  .device[data-device="Lumia930"][data-orientation="portrait"][data-color="white"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="Lumia930"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 51.9986%;
  background-image: url(../device-mockups/Lumia930/landscape_black.png); }
  .device[data-device="Lumia930"][data-orientation="landscape"][data-color="black"] .screen {
    top: 6.60823%;
    left: 7.11781%;
    width: 81.13604%;
    height: 87.86244%; }
  .device[data-device="Lumia930"][data-orientation="landscape"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="Lumia930"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 51.9986%;
  background-image: url(../device-mockups/Lumia930/landscape_white.png); }
  .device[data-device="Lumia930"][data-orientation="landscape"][data-color="white"] .screen {
    top: 6.60823%;
    left: 7.11781%;
    width: 81.13604%;
    height: 87.86244%; }
  .device[data-device="Lumia930"][data-orientation="landscape"][data-color="white"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="Macbook"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 57.51566%;
  background-image: url(../device-mockups/Macbook/portrait_black.png); }
  .device[data-device="Macbook"][data-orientation="portrait"][data-color="black"] .screen {
    top: 7.25953%;
    left: 12.36952%;
    width: 75.26096%;
    height: 81.85118%; }
  .device[data-device="Macbook"][data-orientation="portrait"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="Macbook"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 57.51566%;
  background-image: url(../device-mockups/Macbook/portrait_white.png); }
  .device[data-device="Macbook"][data-orientation="portrait"][data-color="white"] .screen {
    top: 7.25953%;
    left: 12.36952%;
    width: 75.26096%;
    height: 81.85118%; }
  .device[data-device="Macbook"][data-orientation="portrait"][data-color="white"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="Macbook"][data-orientation="portrait"][data-color="gold"] {
  padding-bottom: 57.51566%;
  background-image: url(../device-mockups/Macbook/portrait_gold.png); }
  .device[data-device="Macbook"][data-orientation="portrait"][data-color="gold"] .screen {
    top: 7.25953%;
    left: 12.36952%;
    width: 75.26096%;
    height: 81.85118%; }
  .device[data-device="Macbook"][data-orientation="portrait"][data-color="gold"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="Macbook2015"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 57.51566%;
  background-image: url(../device-mockups/Macbook2015/portrait_black.png); }
  .device[data-device="Macbook2015"][data-orientation="portrait"][data-color="black"] .screen {
    top: 7.35027%;
    left: 12.42171%;
    width: 75.15658%;
    height: 81.76044%; }
  .device[data-device="Macbook2015"][data-orientation="portrait"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="Macbook2015"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 57.51566%;
  background-image: url(../device-mockups/Macbook2015/portrait_white.png); }
  .device[data-device="Macbook2015"][data-orientation="portrait"][data-color="white"] .screen {
    top: 7.35027%;
    left: 12.42171%;
    width: 75.15658%;
    height: 81.76044%; }
  .device[data-device="Macbook2015"][data-orientation="portrait"][data-color="white"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="Macbook2015"][data-orientation="portrait"][data-color="gold"] {
  padding-bottom: 57.51566%;
  background-image: url(../device-mockups/Macbook2015/portrait_gold.png); }
  .device[data-device="Macbook2015"][data-orientation="portrait"][data-color="gold"] .screen {
    top: 7.35027%;
    left: 12.42171%;
    width: 75.15658%;
    height: 81.76044%; }
  .device[data-device="Macbook2015"][data-orientation="portrait"][data-color="gold"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="MacbookPro"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 57.94702%;
  background-image: url(../device-mockups/MacbookPro/portrait_black.png); }
  .device[data-device="MacbookPro"][data-orientation="portrait"][data-color="black"] .screen {
    top: 6.71429%;
    left: 11.83775%;
    width: 76.40728%;
    height: 82.42857%; }
  .device[data-device="MacbookPro"][data-orientation="portrait"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="SamsungTV"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 64.67803%;
  background-image: url(../device-mockups/SamsungTV/portrait_black.png); }
  .device[data-device="SamsungTV"][data-orientation="portrait"][data-color="black"] .screen {
    top: 2.19619%;
    left: 1.32576%;
    width: 97.25379%;
    height: 85.2123%; }
  .device[data-device="SamsungTV"][data-orientation="portrait"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="Surface"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 62.38125%;
  background-image: url(../device-mockups/Surface/portrait_black.png); }
  .device[data-device="Surface"][data-orientation="portrait"][data-color="black"] .screen {
    top: 11.06599%;
    left: 6.77644%;
    width: 86.38379%;
    height: 77.7665%; }
  .device[data-device="Surface"][data-orientation="portrait"][data-color="black"] .button {
    top: 92.18274%;
    left: 48.8917%;
    width: 2.34326%;
    height: 3.65482%; }

.device[data-device="iPhone6Hand"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 89.11784%;
  background-image: url(../device-mockups/iPhone6Hand/portrait_black.png); }
  .device[data-device="iPhone6Hand"][data-orientation="portrait"][data-color="black"] .screen {
    top: 10.83815%;
    left: 10.94656%;
    width: 33.67675%;
    height: 67.26879%; }
  .device[data-device="iPhone6Hand"][data-orientation="portrait"][data-color="black"] .button {
    top: 79.69653%;
    left: 24.79073%;
    width: 5.98841%;
    height: 6.79191%; }

.device[data-device="iPhone6Hand"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 89.11784%;
  background-image: url(../device-mockups/iPhone6Hand/portrait_white.png); }
  .device[data-device="iPhone6Hand"][data-orientation="portrait"][data-color="white"] .screen {
    top: 10.83815%;
    left: 11.07534%;
    width: 33.54797%;
    height: 67.26879%; }
  .device[data-device="iPhone6Hand"][data-orientation="portrait"][data-color="white"] .button {
    top: 79.69653%;
    left: 24.79073%;
    width: 5.98841%;
    height: 6.79191%; }

.device[data-device="iPhone7Hand"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 158.39243%;
  background-image: url(../device-mockups/iPhone7Hand/portrait_black.png); }
  .device[data-device="iPhone7Hand"][data-orientation="portrait"][data-color="black"] .screen {
    top: 7.83582%;
    left: 41.25296%;
    width: 42.19858%;
    height: 47.23881%; }
  .device[data-device="iPhone7Hand"][data-orientation="portrait"][data-color="black"] .button {
    top: 56.19403%;
    left: 58.39243%;
    width: 7.80142%;
    height: 4.92537%; }

.device[data-device="iPhone7Hand_2"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 158.39243%;
  background-image: url(../device-mockups/iPhone7Hand_2/portrait_black.png); }
  .device[data-device="iPhone7Hand_2"][data-orientation="portrait"][data-color="black"] .screen {
    top: 7.83582%;
    left: 41.25296%;
    width: 42.19858%;
    height: 47.23881%; }
  .device[data-device="iPhone7Hand_2"][data-orientation="portrait"][data-color="black"] .button {
    top: 56.19403%;
    left: 58.39243%;
    width: 7.80142%;
    height: 4.92537%; }

.device[data-device="Chromebook"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 58.97364%;
  background-image: url(../device-mockups/Chromebook/portrait_black.png); }
  .device[data-device="Chromebook"][data-orientation="portrait"][data-color="black"] .screen {
    top: 7.89349%;
    left: 12.73135%;
    width: 74.1447%;
    height: 71.27913%; }
  .device[data-device="Chromebook"][data-orientation="portrait"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="HtcOneM8"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 209.0625%;
  background-image: url(../device-mockups/HtcOneM8/portrait_black.png); }
  .device[data-device="HtcOneM8"][data-orientation="portrait"][data-color="black"] .screen {
    top: 10.76233%;
    left: 5.3125%;
    width: 89.0625%;
    height: 75.63528%; }
  .device[data-device="HtcOneM8"][data-orientation="portrait"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="HtcOneM8"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 47.83259%;
  background-image: url(../device-mockups/HtcOneM8/landscape_black.png); }
  .device[data-device="HtcOneM8"][data-orientation="landscape"][data-color="black"] .screen {
    top: 5.625%;
    left: 10.76233%;
    width: 75.63528%;
    height: 89.0625%; }
  .device[data-device="HtcOneM8"][data-orientation="landscape"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="HuaweiP8"][data-orientation="portrait"][data-color="gold"] {
  padding-bottom: 209.74084%;
  background-image: url(../device-mockups/HuaweiP8/portrait_gold.png); }
  .device[data-device="HuaweiP8"][data-orientation="portrait"][data-color="gold"] .screen {
    top: 9.11802%;
    left: 1.69794%;
    width: 95.88919%;
    height: 81.80656%; }
  .device[data-device="HuaweiP8"][data-orientation="portrait"][data-color="gold"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="HuaweiP8"][data-orientation="landscape"][data-color="gold"] {
  padding-bottom: 47.67789%;
  background-image: url(../device-mockups/HuaweiP8/landscape_gold.png); }
  .device[data-device="HuaweiP8"][data-orientation="landscape"][data-color="gold"] .screen {
    top: 2.41287%;
    left: 9.11802%;
    width: 81.80656%;
    height: 95.88919%; }
  .device[data-device="HuaweiP8"][data-orientation="landscape"][data-color="gold"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="SurfacePro3"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 68.15797%;
  background-image: url(../device-mockups/SurfacePro3/landscape_black.png); }
  .device[data-device="SurfacePro3"][data-orientation="landscape"][data-color="black"] .screen {
    top: 7.09076%;
    left: 5.82712%;
    width: 88.34576%;
    height: 86.42626%; }
  .device[data-device="SurfacePro3"][data-orientation="landscape"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="SurfaceStudio"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 86.48036%;
  background-image: url(../device-mockups/SurfaceStudio/portrait_black.png); }
  .device[data-device="SurfaceStudio"][data-orientation="portrait"][data-color="black"] .screen {
    top: 3.75546%;
    left: 3.22256%;
    width: 93.52971%;
    height: 72.40175%; }
  .device[data-device="SurfaceStudio"][data-orientation="portrait"][data-color="black"] .button {
    display: none;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%; }

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 203.10345%;
  background-image: url(../device-mockups/iPhone7/portrait_black.png); }
  .device[data-device="iPhone7"][data-orientation="portrait"][data-color="black"] .screen {
    top: 11.99774%;
    left: 6.2069%;
    width: 87.35632%;
    height: 76.06112%; }
  .device[data-device="iPhone7"][data-orientation="portrait"][data-color="black"] .button {
    top: 89.53028%;
    left: 42.29885%;
    width: 15.74713%;
    height: 7.75325%; }

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 203.10345%;
  background-image: url(../device-mockups/iPhone7/portrait_white.png); }
  .device[data-device="iPhone7"][data-orientation="portrait"][data-color="white"] .screen {
    top: 11.99774%;
    left: 6.2069%;
    width: 87.35632%;
    height: 76.06112%; }
  .device[data-device="iPhone7"][data-orientation="portrait"][data-color="white"] .button {
    top: 89.53028%;
    left: 42.29885%;
    width: 15.74713%;
    height: 7.75325%; }

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="gold"] {
  padding-bottom: 203.10345%;
  background-image: url(../device-mockups/iPhone7/portrait_gold.png); }
  .device[data-device="iPhone7"][data-orientation="portrait"][data-color="gold"] .screen {
    top: 11.99774%;
    left: 6.2069%;
    width: 87.35632%;
    height: 76.06112%; }
  .device[data-device="iPhone7"][data-orientation="portrait"][data-color="gold"] .button {
    top: 89.53028%;
    left: 42.29885%;
    width: 15.74713%;
    height: 7.75325%; }

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="pink"] {
  padding-bottom: 203.10345%;
  background-image: url(../device-mockups/iPhone7/portrait_pink.png); }
  .device[data-device="iPhone7"][data-orientation="portrait"][data-color="pink"] .screen {
    top: 11.99774%;
    left: 6.2069%;
    width: 87.35632%;
    height: 76.06112%; }
  .device[data-device="iPhone7"][data-orientation="portrait"][data-color="pink"] .button {
    top: 89.53028%;
    left: 42.29885%;
    width: 15.74713%;
    height: 7.75325%; }

.device[data-device="iPhone7"][data-orientation="portrait"][data-color="red"] {
  padding-bottom: 201.91083%;
  background-image: url(../device-mockups/iPhone7/portrait_red.png); }
  .device[data-device="iPhone7"][data-orientation="portrait"][data-color="red"] .screen {
    top: 11.98738%;
    left: 6.6879%;
    width: 84.71338%;
    height: 75.55205%; }
  .device[data-device="iPhone7"][data-orientation="portrait"][data-color="red"] .button {
    top: 89.74763%;
    left: 42.35669%;
    width: 15.6051%;
    height: 7.72871%; }

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="black"] {
  padding-bottom: 49.23599%;
  background-image: url(../device-mockups/iPhone7/landscape_black.png); }
  .device[data-device="iPhone7"][data-orientation="landscape"][data-color="black"] .screen {
    top: 6.43678%;
    left: 11.99774%;
    width: 76.06112%;
    height: 87.35632%; }
  .device[data-device="iPhone7"][data-orientation="landscape"][data-color="black"] .button {
    top: 41.95402%;
    left: 89.53028%;
    width: 7.75325%;
    height: 15.74713%; }

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="white"] {
  padding-bottom: 49.23599%;
  background-image: url(../device-mockups/iPhone7/landscape_white.png); }
  .device[data-device="iPhone7"][data-orientation="landscape"][data-color="white"] .screen {
    top: 6.43678%;
    left: 11.99774%;
    width: 76.06112%;
    height: 87.35632%; }
  .device[data-device="iPhone7"][data-orientation="landscape"][data-color="white"] .button {
    top: 41.95402%;
    left: 89.53028%;
    width: 7.75325%;
    height: 15.74713%; }

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="gold"] {
  padding-bottom: 49.23599%;
  background-image: url(../device-mockups/iPhone7/landscape_gold.png); }
  .device[data-device="iPhone7"][data-orientation="landscape"][data-color="gold"] .screen {
    top: 6.43678%;
    left: 11.99774%;
    width: 76.06112%;
    height: 87.35632%; }
  .device[data-device="iPhone7"][data-orientation="landscape"][data-color="gold"] .button {
    top: 41.95402%;
    left: 89.53028%;
    width: 7.75325%;
    height: 15.74713%; }

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="pink"] {
  padding-bottom: 49.23599%;
  background-image: url(../device-mockups/iPhone7/landscape_pink.png); }
  .device[data-device="iPhone7"][data-orientation="landscape"][data-color="pink"] .screen {
    top: 6.43678%;
    left: 11.99774%;
    width: 76.06112%;
    height: 87.35632%; }
  .device[data-device="iPhone7"][data-orientation="landscape"][data-color="pink"] .button {
    top: 41.95402%;
    left: 89.53028%;
    width: 7.75325%;
    height: 15.74713%; }

.device[data-device="iPhone7"][data-orientation="landscape"][data-color="red"] {
  padding-bottom: 49.52681%;
  background-image: url(../device-mockups/iPhone7/landscape_red.png); }
  .device[data-device="iPhone7"][data-orientation="landscape"][data-color="red"] .screen {
    top: 8.59873%;
    left: 11.98738%;
    width: 75.55205%;
    height: 84.71338%; }
  .device[data-device="iPhone7"][data-orientation="landscape"][data-color="red"] .button {
    top: 42.03822%;
    left: 89.74763%;
    width: 7.72871%;
    height: 15.6051%; }

.device[data-device="Pixel"][data-orientation="portrait"][data-color="white"] {
  padding-bottom: 202.62009%;
  background-image: url(../device-mockups/Pixel/portrait_white.png); }
  .device[data-device="Pixel"][data-orientation="portrait"][data-color="white"] .screen {
    top: 9.69828%;
    left: 4.36681%;
    width: 90.39301%;
    height: 78.66379%; }
  .device[data-device="Pixel"][data-orientation="portrait"][data-color="white"] .button {
    top: 90.51724%;
    left: 44.97817%;
    width: 11.35371%;
    height: 4.31034%; }

.device[data-device="Pixel"][data-orientation="portrait"][data-color="black"] {
  padding-bottom: 202.62009%;
  background-image: url(../device-mockups/Pixel/portrait_black.png); }
  .device[data-device="Pixel"][data-orientation="portrait"][data-color="black"] .screen {
    top: 9.69828%;
    left: 4.36681%;
    width: 90.39301%;
    height: 78.66379%; }
  .device[data-device="Pixel"][data-orientation="portrait"][data-color="black"] .button {
    top: 90.51724%;
    left: 44.97817%;
    width: 11.35371%;
    height: 4.31034%; }
